/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { Headers, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'
import * as AppBskyActorDefs from '../actor/defs'

export interface QueryParams {
  uri: string
  cid?: string
  limit?: number
  cursor?: string
}

export type InputSchema = undefined

export interface OutputSchema {
  uri: string
  cid?: string
  cursor?: string
  likes: Like[]
  [k: string]: unknown
}

export interface CallOptions {
  headers?: Headers
}

export interface Response {
  success: boolean
  headers: Headers
  data: OutputSchema
}

export function toKnownErr(e: any) {
  if (e instanceof XRPCError) {
  }
  return e
}

export interface Like {
  indexedAt: string
  createdAt: string
  actor: AppBskyActorDefs.ProfileView
  [k: string]: unknown
}

export function isLike(v: unknown): v is Like {
  return (
    isObj(v) && hasProp(v, '$type') && v.$type === 'app.bsky.feed.getLikes#like'
  )
}

export function validateLike(v: unknown): ValidationResult {
  return lexicons.validate('app.bsky.feed.getLikes#like', v)
}
