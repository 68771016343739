"use strict";

const F = BigInt('21888242871839275222246405745257275088548364400416034343698204186575808495617');

// Parameters are generated by a reference script https://extgit.iaik.tugraz.at/krypto/hadeshash/-/blob/master/code/generate_parameters_grain.sage
// Used like so: sage generate_parameters_grain.sage 1 0 254 2 8 56 0x30644e72e131a029b85045b68181585d2833e84879b9709143e1f593f0000001

// Using recommended parameters from whitepaper https://eprint.iacr.org/2019/458.pdf (table 2, table 8)
// Generated by https://extgit.iaik.tugraz.at/krypto/hadeshash/-/blob/master/code/calc_round_numbers.py
// And rounded up to nearest integer that divides by t
const N_ROUNDS_F = 8;
const N_ROUNDS_P = [56, 57, 56, 60, 60, 63, 64, 63, 60, 66, 60, 65, 70, 60, 64, 68];
const pow5 = v => {
  let o = v * v;
  return v * o * o % F;
};
function mix(state, M) {
  const out = [];
  for (let x = 0; x < state.length; x++) {
    let o = 0n;
    for (let y = 0; y < state.length; y++) {
      o = o + M[x][y] * state[y];
    }
    out.push(o % F);
  }
  return out;
}
function poseidon(_inputs, opt) {
  const inputs = _inputs.map(i => BigInt(i));
  if (inputs.length <= 0) {
    throw new Error('poseidon-lite: Not enough inputs');
  }
  if (inputs.length > N_ROUNDS_P.length) {
    throw new Error('poseidon-lite: Too many inputs');
  }
  const t = inputs.length + 1;
  const nRoundsF = N_ROUNDS_F;
  const nRoundsP = N_ROUNDS_P[t - 2];
  const {
    C,
    M
  } = opt;
  if (M.length !== t) {
    throw new Error(`poseidon-lite: Incorrect M length, expected ${t} got ${M.length}`);
  }
  let state = [0n, ...inputs];
  for (let x = 0; x < nRoundsF + nRoundsP; x++) {
    for (let y = 0; y < state.length; y++) {
      state[y] = state[y] + C[x * t + y];
      if (x < nRoundsF / 2 || x >= nRoundsF / 2 + nRoundsP) state[y] = pow5(state[y]);else if (y === 0) state[y] = pow5(state[y]);
    }
    state = mix(state, M);
  }
  return state[0];
}
module.exports = poseidon;