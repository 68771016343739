"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildDelegateAction = exports.DelegateAction = void 0;
const types_1 = require("@near-js/types");
class DelegateAction extends types_1.Assignable {
}
exports.DelegateAction = DelegateAction;
/**
 * Compose a delegate action for inclusion with a meta transaction signed on the sender's behalf
 * @params.actions The set of actions to be included in the meta transaction
 * @params.maxBlockHeight The maximum block height for which this action can be executed as part of a transaction
 * @params.nonce Current nonce on the access key used to sign the delegate action
 * @params.publicKey Public key for the access key used to sign the delegate action
 * @params.receiverId Account ID for the intended receiver of the meta transaction
 * @params.senderId Account ID for the intended signer of the delegate action
 */
function buildDelegateAction({ actions, maxBlockHeight, nonce, publicKey, receiverId, senderId, }) {
    return new DelegateAction({
        senderId,
        receiverId,
        actions,
        nonce,
        maxBlockHeight,
        publicKey,
    });
}
exports.buildDelegateAction = buildDelegateAction;
