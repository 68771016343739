"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = unstringifyBigInts;
function unstringifyBigInts(o) {
  if (Array.isArray(o)) {
    return o.map(unstringifyBigInts);
  } else if (typeof o == 'object') {
    const res = {};
    for (const [key, val] of Object.entries(o)) {
      res[key] = unstringifyBigInts(val);
    }
    return res;
  }
  // base64 decode
  const byteArray = Uint8Array.from(atob(o), c => c.charCodeAt(0));
  const hex = [...byteArray].map(x => x.toString(16).padStart(2, '0')).join('');
  return BigInt(`0x${hex}`);
}