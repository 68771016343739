"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "poseidon1", {
  enumerable: true,
  get: function () {
    return _poseidon.poseidon1;
  }
});
Object.defineProperty(exports, "poseidon10", {
  enumerable: true,
  get: function () {
    return _poseidon10.poseidon10;
  }
});
Object.defineProperty(exports, "poseidon11", {
  enumerable: true,
  get: function () {
    return _poseidon11.poseidon11;
  }
});
Object.defineProperty(exports, "poseidon12", {
  enumerable: true,
  get: function () {
    return _poseidon12.poseidon12;
  }
});
Object.defineProperty(exports, "poseidon13", {
  enumerable: true,
  get: function () {
    return _poseidon13.poseidon13;
  }
});
Object.defineProperty(exports, "poseidon14", {
  enumerable: true,
  get: function () {
    return _poseidon14.poseidon14;
  }
});
Object.defineProperty(exports, "poseidon15", {
  enumerable: true,
  get: function () {
    return _poseidon15.poseidon15;
  }
});
Object.defineProperty(exports, "poseidon16", {
  enumerable: true,
  get: function () {
    return _poseidon16.poseidon16;
  }
});
Object.defineProperty(exports, "poseidon2", {
  enumerable: true,
  get: function () {
    return _poseidon2.poseidon2;
  }
});
Object.defineProperty(exports, "poseidon3", {
  enumerable: true,
  get: function () {
    return _poseidon3.poseidon3;
  }
});
Object.defineProperty(exports, "poseidon4", {
  enumerable: true,
  get: function () {
    return _poseidon4.poseidon4;
  }
});
Object.defineProperty(exports, "poseidon5", {
  enumerable: true,
  get: function () {
    return _poseidon5.poseidon5;
  }
});
Object.defineProperty(exports, "poseidon6", {
  enumerable: true,
  get: function () {
    return _poseidon6.poseidon6;
  }
});
Object.defineProperty(exports, "poseidon7", {
  enumerable: true,
  get: function () {
    return _poseidon7.poseidon7;
  }
});
Object.defineProperty(exports, "poseidon8", {
  enumerable: true,
  get: function () {
    return _poseidon8.poseidon8;
  }
});
Object.defineProperty(exports, "poseidon9", {
  enumerable: true,
  get: function () {
    return _poseidon9.poseidon9;
  }
});
var _poseidon = require("./poseidon1");
var _poseidon2 = require("./poseidon2");
var _poseidon3 = require("./poseidon3");
var _poseidon4 = require("./poseidon4");
var _poseidon5 = require("./poseidon5");
var _poseidon6 = require("./poseidon6");
var _poseidon7 = require("./poseidon7");
var _poseidon8 = require("./poseidon8");
var _poseidon9 = require("./poseidon9");
var _poseidon10 = require("./poseidon10");
var _poseidon11 = require("./poseidon11");
var _poseidon12 = require("./poseidon12");
var _poseidon13 = require("./poseidon13");
var _poseidon14 = require("./poseidon14");
var _poseidon15 = require("./poseidon15");
var _poseidon16 = require("./poseidon16");